/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react"
import { AnimatePresence } from "framer-motion"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ProductPopup from "components/ProductPopup"

import { ContentSingle } from "page_components/glues"
import { Quality } from "page_components/home"

const Glues = ({ pageContext, data, location }) => {
  const isBrowser = typeof window !== "undefined"
  const [popup, setPopup] = useState(false)
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)
  const pageDataHome = data?.allWpPageHome?.nodes[0]

  useEffect(() => {
    if (isBrowser) {
      window.gtag("event", "view_item", {
        currency: "PLN",
        value: pageContext?.data?.data.price,
        items: [
          {
            item_id: pageContext?.data?.data?.databaseId,
            item_name: pageContext?.data?.data?.name,
            price: pageContext?.data?.data?.price,
            quantity: 1,
          },
        ],
      })
    }
  }, [])

  return (
    <Layout location={location}>
      <Breadcrumbs
        parent={{ slug: "/kleje-do-tapet/", title: "Kleje do tapet" }}
        title={pageContext?.data?.data?.name}
      />
      <PageHeader title={pageContext?.data?.data?.name} />
      <ContentSingle data={pageContext?.data?.data} setPopup={setPopup} />
      <Quality pageData={pageDataHome} rmHeader />
      <AnimatePresence>
        {popup && <ProductPopup popup={popup} setPopup={setPopup} />}
      </AnimatePresence>
      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export const Head = ({ pageContext }) => (
  <Seo
    title={
      pageContext?.data?.data?.seo?.title
        ? pageContext?.data?.data?.seo?.title
        : pageContext?.data?.data?.name
    }
    description={
      pageContext?.data?.data?.seo?.metaDesc &&
      pageContext?.data?.data?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPageHome: allWpPage(filter: { id: { eq: "cG9zdDoxMDA2MQ==" } }) {
      nodes {
        acfHome {
          qualityHeader
          qualitySubHeader
          qualityContentHeader
          qualityContentDescription
          qualityContentImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Glues
